import React from 'react'
import { Box, Card, Typography } from '@mui/material'

import NoResultsImage from './NoResultsImage'

interface NoResultsPageProps {
  /**  Optional title for use cases other than default no results page
   * @default 'Här var det lugnt'
   */
  title?: string
  /**  Optional subtitle for use cases other than default no results page
   * @default 'Ingen ökad aktivitet har identifierats.''
   */
  subtitle?: string
}

const NoResultsPage = ({
  title = 'Här var det lugnt',
  subtitle = 'Ingen ökad aktivitet har identifierats.',
}: NoResultsPageProps) => {
  return (
    <Card
      data-testid="no-results-page"
      sx={{
        width: '100%',
        minHeight: { xs: '400px', md: '500px' },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: (theme) => theme.palette.surface?.grey,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <NoResultsImage />
        <Typography variant="body1" fontWeight="bold" mt={1}>
          {title}
        </Typography>
        <Typography variant="body1" color="text.secondary" mt={0.5}>
          {subtitle}
        </Typography>
      </Box>
    </Card>
  )
}

export default NoResultsPage
