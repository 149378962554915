import React from 'react'
import { IconButton, TableCell, TableRow } from '@mui/material'
import { Email } from '@mui/icons-material'

interface UserActivityTableRowProps {
  name: string
  loginCount: number
  emailAddress?: string
  ansokningarCount: number
}

const UserActivityTableRow = ({
  name,
  loginCount,
  emailAddress,
  ansokningarCount,
}: UserActivityTableRowProps) => {
  return (
    <TableRow data-testid="user-activity-row">
      <TableCell>{name}</TableCell>
      <TableCell align="right" data-testid="ansokningar-count">
        {ansokningarCount}
      </TableCell>
      <TableCell align="right" data-testid="logins-count">
        {loginCount}
      </TableCell>
      <TableCell align="right">
        {emailAddress && (
          <IconButton
            color="primary"
            href={`mailto:${emailAddress}`}
            data-testid="email-button"
          >
            <Email />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}

export default UserActivityTableRow
