import React, { PropsWithChildren } from 'react'
import { Box } from '@mui/material'

// React throws error if buttons are nested inside a button.
// This is a workaround which keeps the styling of the IconButton.
const IconButtonWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        justifyContent: 'center',
        position: 'relative',
        padding: 1,
        borderRadius: '50%',
        backgroundColor: 'transparent',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        ':hover': {
          backgroundColor: (theme) => theme.palette.surface?.grey,
        },
      }}
    >
      {children}
    </Box>
  )
}

export default IconButtonWrapper
