import { ThemeProvider, createTheme } from '@mui/material'
import { ApiProvider } from '@reduxjs/toolkit/dist/query/react'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import App from 'App'
import api from 'services'
import React from 'react'

const Index = ({ appShellData }: { appShellData: AppShellDataProps }) => {
  const theme = createTheme(themeOptions, defaultLocale)

  return (
    <AppShellDataProvider value={appShellData}>
      <ApiProvider api={api}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ApiProvider>
    </AppShellDataProvider>
  )
}

export default Index
