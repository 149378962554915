import React from 'react'
import { AxisConfig, LineChart, LineSeriesType } from '@mui/x-charts'
import { Box, useTheme } from '@mui/material'
import { generateXAxis, getHighestCount, getLoginCountPerDay } from 'utils'
import { formatDate, getLoginFrequency } from 'utils/dates'
import { MakeOptional } from '@mui/x-charts/models/helpers'

interface GraphViewProps {
  loginDates: string[]
  loginPeriod: number
}

const GraphView = ({ loginDates, loginPeriod }: GraphViewProps) => {
  const theme = useTheme()

  // Generate an x axis based on how many days the user wants to see
  // This is to ensure that we can set 0 values on the y axis for days
  // that are without logins
  const xAxisDates = generateXAxis(loginPeriod)

  const loginFrequencyMap = getLoginFrequency(loginDates)

  const loginCountPerDay: number[] = getLoginCountPerDay(
    xAxisDates,
    loginFrequencyMap
  )
  const highestCount = getHighestCount(loginCountPerDay)

  const getMaxYAxisDimension = (): number => {
    // If the logins don't have a peak in the graph the line looks very bulky
    // If the y-axis is set to a higher number, which increases the height of the graph,
    // the x axis is displayed closer to the bottom for a better data representation.
    if (highestCount === 1) return 10
    else return highestCount * 3
  }

  const series: MakeOptional<LineSeriesType, 'type'>[] = [
    {
      data: loginCountPerDay,
      area: true,
      curve: 'linear',
      showMark: ({ value }) => value >= 1,
      connectNulls: true,
      color: theme.palette.info.main,
      label: 'Antal inloggningar',
    },
  ]

  const xAxisSettings: MakeOptional<AxisConfig, 'id'>[] = [
    {
      min: new Date(xAxisDates[0]),
      max: new Date(xAxisDates[xAxisDates.length - 1]),
      // Without this, the graph lib will show duplicate dates on the x-axis
      tickInterval: xAxisDates.map((date) => new Date(date)),
      data: xAxisDates.map((date) => new Date(date)),
      valueFormatter: formatDate,
      scaleType: 'time',
      labelStyle: {
        fontSize: 14,
        transform: `translateY(${
          // Hack that should be added in the lib latter.
          5 * Math.abs(Math.sin((Math.PI * 45) / 180))
        }px)`,
        paddingBottom: 10,
      },
      tickLabelStyle: {
        angle: 45,
        textAnchor: 'start',
        fontSize: 12,
      },
    },
  ]

  const yAxisSettings: MakeOptional<AxisConfig, 'id'>[] = [
    {
      min: 0,
      max: getMaxYAxisDimension(),
      label: 'Antal inloggningar',
    },
  ]

  return (
    <Box height={420}>
      <LineChart
        sx={{
          '& .MuiAreaElement-root': {
            fill: theme.palette.surface?.blue,
          },
        }}
        slotProps={{
          legend: { hidden: true },
        }}
        series={series}
        xAxis={xAxisSettings}
        yAxis={yAxisSettings}
        margin={{ bottom: 100, right: 50 }}
      />
    </Box>
  )
}

export default GraphView
