import { Box } from '@mui/material'
import React from 'react'

const NoResultsImage = () => {
  return (
    <Box py={2.5}>
      <svg
        width="157"
        height="114"
        viewBox="0 0 157 114"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Vector">
          <path
            id="Vector_2"
            d="M58.7505 28.1562C58.8444 29.0953 58.4688 34.8252 58.4688 34.8252C59.7836 35.5765 62.6023 32.9843 64.3866 32.1018C66.7109 30.9514 70.3154 30.3368 73.9683 33.3226C77.7026 36.3748 78.0548 41.9404 75.6587 46.4729C75.2402 47.2643 74.6229 48.0598 73.8771 48.8414C76.4901 48.7531 78.4498 48.5611 79.9385 48.3015C79.7756 44.9096 79.8377 42.3712 79.8377 41.9873C79.8377 41.0482 80.3073 40.3908 82.3733 40.2969C82.8884 40.2734 84.3012 40.2458 86.2651 40.2168C88.3933 39.0609 90.893 37.3097 92.1456 36.4742C93.836 35.3473 99.5659 32.7164 102.572 31.2138C105.579 29.7112 113.41 25.3623 115.629 21.1625C118.024 16.6313 117.671 11.0657 113.938 8.01224C110.287 5.02642 106.682 5.64098 104.357 6.79139C102.572 7.67388 99.7551 10.2675 98.439 9.51481C98.439 9.51481 98.8146 3.78486 98.7207 2.84575C98.6268 1.90664 99.2842 1.06144 94.8704 0.873616C90.4552 0.685794 74.7693 0.20381 70.2602 0.216239C61.6176 0.239717 45.0865 0.404063 43.0191 0.497974C40.953 0.591885 40.4821 1.24926 40.4821 2.18837C40.4821 3.12748 40.1064 16.9352 43.5825 24.6386C43.7621 25.0364 43.9499 25.43 44.1432 25.8222C48.6468 25.9534 52.9142 26.0984 54.9002 26.1826C59.3154 26.3705 58.6566 27.2157 58.7505 28.1548V28.1562ZM94.4934 27.6921C95.3386 26.0722 96.8177 21.3517 97.0994 20.2248C97.3811 19.0979 97.522 18.0414 98.1559 17.9005C98.7898 17.7597 99.646 18.5165 100.339 19.2388C102.031 20.9996 103.017 20.4361 103.299 20.1544C103.581 19.8727 103.792 18.8161 103.228 18.0414C102.665 17.2666 101.961 15.9988 101.89 14.3789C101.82 12.7575 103.721 10.5741 106.54 9.86974C109.359 9.16541 111.754 10.7149 112.881 13.0406C114.008 15.3649 113.162 18.5358 111.12 21.1418C109.077 23.7479 104.216 26.4961 101.751 27.6231C99.2856 28.75 96.7486 30.0178 95.1286 30.863C93.5087 31.7082 92.593 31.1447 92.593 31.1447C92.593 31.1447 93.6495 29.3135 94.4947 27.6935L94.4934 27.6921Z"
            fill="#6222C3"
          />
          <path
            id="Vector_3"
            d="M71.1496 46.4509C73.1921 43.8434 74.0387 40.6739 72.9104 38.3496C71.7835 36.0253 69.3874 34.4744 66.57 35.1788C63.7513 35.8831 61.8496 38.0665 61.9201 39.6879C61.9905 41.3078 62.6948 42.5756 63.2583 43.3504C63.8218 44.1252 63.6105 45.1817 63.3287 45.4634C63.047 45.7451 62.0609 46.3086 60.3691 44.5478C59.6759 43.8255 58.8196 43.0687 58.1857 43.2095C57.5518 43.3504 57.4109 44.4069 57.1292 45.5338C57.056 45.8266 56.9027 46.3611 56.6997 47.0198C58.5406 47.8374 62.8481 48.7185 68.5767 48.8732C69.6028 48.0874 70.5129 47.2615 71.1482 46.4495L71.1496 46.4509Z"
            fill="#0C3BE9"
          />
          <path
            id="Vector_4"
            d="M39.8661 67.2977C39.8661 66.3586 40.3357 65.7012 42.4031 65.6073C42.9182 65.5838 44.331 65.5562 46.2949 65.5272C48.4231 64.3713 50.9228 62.6201 52.1754 61.7846C53.8658 60.6576 59.5957 58.0268 62.6022 56.5242C65.0426 55.3047 70.6606 52.2098 73.8771 48.8414C72.8137 48.8773 71.6453 48.8967 70.354 48.8967C69.7478 48.8967 69.1567 48.8884 68.578 48.8732C66.3103 50.6106 63.4778 52.1546 61.7805 52.9307C59.3154 54.0576 56.7784 55.3254 55.1584 56.1706C53.5385 57.0158 52.6228 56.4524 52.6228 56.4524C52.6228 56.4524 53.6793 54.6211 54.5245 53.0011C55.1501 51.801 56.1238 48.9008 56.701 47.0198C56.6831 47.0116 56.6638 47.0033 56.6458 46.995C55.273 46.3694 54.7621 45.7272 55.1363 44.1776C55.2924 43.5327 56.1693 41.1711 56.1693 41.1711C53.4073 39.2998 47.6911 33.0354 44.1432 25.8236C38.6342 25.662 32.7703 25.5197 30.2899 25.5266C21.6474 25.5501 5.11627 25.7145 3.04884 25.8084C0.9828 25.9023 0.511864 26.5597 0.511864 27.4988C0.511864 28.4379 0.13622 42.2456 3.61231 49.949C7.0884 57.6511 13.2879 64.508 16.1992 66.4801C16.1992 66.4801 15.3222 68.8403 15.1661 69.4867C14.7905 71.0362 15.3029 71.6784 16.6756 72.304C18.6657 73.2114 23.7148 74.2057 30.3839 74.2057C34.8502 74.2057 37.8733 73.9751 39.9669 73.6105C39.804 70.2186 39.8661 67.6803 39.8661 67.2963V67.2977Z"
            fill="#E7D9FC"
          />
          <path
            id="Vector_5"
            d="M56.1708 41.1725C56.1708 41.1725 55.2938 43.5327 55.1377 44.179C54.7621 45.7286 55.2745 46.3707 56.6472 46.9964C56.6652 47.0046 56.6845 47.0129 56.7025 47.0212C56.9041 46.3625 57.0588 45.828 57.132 45.5352C57.4137 44.4083 57.5546 43.3518 58.1885 43.2109C58.8224 43.07 59.6786 43.8269 60.3719 44.5491C62.0623 46.31 63.0497 45.7465 63.3315 45.4648C63.6132 45.183 63.8245 44.1265 63.261 43.3518C62.6976 42.577 61.9932 41.3092 61.9228 39.6892C61.8524 38.0679 63.7541 35.8845 66.5728 35.1801C69.3915 34.4758 71.7862 36.0253 72.9132 38.351C74.0401 40.6753 73.1949 43.8462 71.1523 46.4522C70.5157 47.2643 69.6056 48.0901 68.5808 48.876C69.1581 48.8911 69.7506 48.8994 70.3568 48.8994C71.6481 48.8994 72.8165 48.8801 73.8799 48.8442C74.627 48.0625 75.2444 47.267 75.6614 46.4757C78.0562 41.9445 77.704 36.3789 73.971 33.3254C70.3196 30.3396 66.715 30.9541 64.3894 32.1046C62.605 32.987 59.7877 35.5806 58.4716 34.828C58.4716 34.828 58.8472 29.098 58.7533 28.1589C58.6594 27.2198 59.3168 26.3746 54.903 26.1868C52.917 26.1025 48.651 25.9589 44.146 25.8263C47.6939 33.0381 53.4114 39.3039 56.1721 41.1739L56.1708 41.1725Z"
            fill="#D5DCF6"
          />
          <path
            id="Vector_6"
            d="M110.504 86.2484C112.546 83.641 113.393 80.4715 112.265 78.1472C111.138 75.8229 108.742 74.2719 105.924 74.9763C103.106 75.6806 101.204 77.864 101.274 79.4854C101.345 81.1054 102.049 82.3732 102.613 83.1479C103.176 83.9227 102.965 84.9792 102.683 85.2609C102.401 85.5426 101.415 86.1061 99.7234 84.3453C99.0301 83.623 98.1739 82.8662 97.54 83.0071C96.9061 83.1479 96.7652 84.2044 96.4835 85.3314C96.4103 85.6241 96.257 86.1586 96.054 86.8174C97.8949 87.6349 102.202 88.516 107.931 88.6707C108.957 87.8849 109.867 87.059 110.502 86.247L110.504 86.2484Z"
            fill="#D5DCF6"
          />
          <path
            id="Vector_7"
            d="M153.291 47.8125C149.64 44.8267 146.035 45.4413 143.71 46.5917C141.925 47.4742 139.108 50.0664 137.792 49.3151C137.792 49.3151 138.167 43.5852 138.074 42.646C137.98 41.7069 138.637 40.8617 134.223 40.6739C129.808 40.4861 114.122 40.0041 109.613 40.0165C103.123 40.0345 92.1856 40.1312 86.265 40.2182C85.5207 40.6228 84.8232 40.9543 84.2391 41.149C84.2391 41.149 84.6727 42.7897 85.0483 44.2922C85.424 45.7948 84.8868 46.252 83.7874 46.995C83.0431 47.499 81.8968 47.9589 79.9385 48.3014C80.1746 53.2014 80.8831 59.8856 82.9381 64.4375C83.1176 64.8353 83.3055 65.2289 83.4988 65.6211C88.0024 65.7537 92.2698 65.8973 94.2558 65.9816C98.671 66.1694 98.0122 67.0146 98.1061 67.9537C98.2 68.8928 97.8244 74.6228 97.8244 74.6228C99.1391 75.374 101.958 72.7818 103.742 71.8993C106.066 70.7489 109.672 70.1344 113.324 73.1202C117.058 76.1737 117.41 81.7379 115.014 86.2705C114.596 87.0618 113.978 87.8573 113.233 88.639C119.282 88.4332 121.832 87.6791 123.142 86.7925C124.241 86.0495 124.78 85.5924 124.403 84.0898C124.027 82.5872 123.593 80.9452 123.593 80.9452C125.847 80.1939 129.809 77.4 131.5 76.2717C133.19 75.1448 138.92 72.5139 141.927 71.0113C144.933 69.5087 152.764 65.1598 154.983 60.9601C157.378 56.4289 157.026 50.8633 153.293 47.8098L153.291 47.8125ZM150.473 60.9394C148.43 63.5454 143.569 66.2937 141.104 67.4206C138.638 68.5475 136.101 69.8153 134.481 70.6605C132.861 71.5057 131.946 70.9423 131.946 70.9423C131.946 70.9423 133.002 69.111 133.848 67.491C134.693 65.8711 136.172 61.1507 136.454 60.0237C136.735 58.8968 136.876 57.8403 137.51 57.6994C138.144 57.5586 139 58.3154 139.694 59.0377C141.384 60.7985 142.371 60.235 142.653 59.9533C142.935 59.6716 143.146 58.6151 142.583 57.8389C142.019 57.0641 141.315 55.7964 141.244 54.1764C141.174 52.555 143.076 50.3716 145.894 49.6673C148.713 48.9629 151.108 50.5125 152.235 52.8382C153.362 55.1625 152.517 58.3333 150.474 60.9394H150.473Z"
            fill="#D5DCF6"
          />
          <path
            id="Vector_8"
            d="M79.8363 41.9887C79.8363 42.3726 79.7742 44.911 79.9372 48.3028C81.8955 47.9603 83.0418 47.5004 83.7861 46.9964C84.8854 46.2534 85.4241 45.7962 85.047 44.2937C84.6714 42.7911 84.2377 41.1504 84.2377 41.1504C84.8219 40.9557 85.5207 40.6242 86.2637 40.2196C84.2985 40.2486 82.8857 40.2762 82.3719 40.2997C80.3059 40.3936 79.8363 41.051 79.8363 41.9901V41.9887Z"
            fill="#E7D9FC"
          />
          <path
            id="Vector_9"
            d="M107.931 88.6721C105.663 90.4095 102.831 91.9535 101.134 92.7296C98.6683 93.8566 96.1314 95.1244 94.5114 95.9696C92.8914 96.8148 91.9758 96.2513 91.9758 96.2513C91.9758 96.2513 93.0323 94.42 93.8775 92.8001C94.5031 91.5999 95.4767 88.6997 96.054 86.8188C96.0361 86.8105 96.0167 86.8022 95.9988 86.7939C94.626 86.1683 94.115 85.5261 94.4893 83.9766C94.6454 83.3316 95.5223 80.97 95.5223 80.97C92.7602 79.1001 87.0441 72.8343 83.4962 65.6225C77.9872 65.4609 72.1233 65.3187 69.6415 65.3256C63.152 65.3435 52.2155 65.4402 46.2936 65.5272C45.5506 65.9318 44.8518 66.2633 44.2676 66.458C44.2676 66.458 44.7013 68.0987 45.0769 69.6027C45.4525 71.1052 44.9153 71.5624 43.816 72.3054C43.0716 72.8094 41.9254 73.2693 39.967 73.6118C40.2032 78.5118 40.9117 85.196 42.9667 89.748C46.4428 97.45 52.6423 104.307 55.5535 106.279C55.5535 106.279 54.6765 108.639 54.5205 109.286C54.1448 110.835 54.6572 111.477 56.03 112.103C58.0201 113.01 63.0692 114.005 69.7382 114.005C78.3794 114.005 81.6304 113.144 83.1703 112.103C84.2696 111.36 84.8082 110.903 84.4312 109.4C84.0555 107.898 83.6219 106.256 83.6219 106.256C85.8757 105.504 89.8379 102.71 91.5283 101.582C93.2187 100.455 98.9487 97.8243 101.955 96.3217C104.396 95.1023 110.014 92.0073 113.23 88.639C112.167 88.6749 110.998 88.6942 109.707 88.6942C109.101 88.6942 108.51 88.6859 107.931 88.6707V88.6721Z"
            fill="#0C3BE9"
          />
          <path
            id="Vector_10"
            d="M45.077 69.6013C44.7013 68.0988 44.2677 66.4567 44.2677 66.4567C44.8519 66.262 45.5507 65.9305 46.2937 65.5259C44.3298 65.5549 42.917 65.5825 42.4019 65.606C40.3358 65.6999 39.8649 66.3573 39.8649 67.2964C39.8649 67.6803 39.8028 70.2187 39.9657 73.6105C41.924 73.268 43.0703 72.8081 43.8147 72.304C44.914 71.561 45.4526 71.1039 45.0756 69.6013H45.077Z"
            fill="#0C3BE9"
          />
          <path
            id="Vector_11"
            d="M113.321 73.1216C109.67 70.1358 106.064 70.7503 103.739 71.9007C101.955 72.7832 99.1364 75.3768 97.8217 74.6241C97.8217 74.6241 98.1973 68.8942 98.1034 67.9551C98.0095 67.016 98.6669 66.1708 94.2531 65.9829C92.2671 65.8987 88.0011 65.7551 83.4961 65.6225C87.044 72.8343 92.7615 79.1001 95.5222 80.9701C95.5222 80.9701 94.6453 83.3303 94.4892 83.9766C94.1136 85.5261 94.6259 86.1683 95.9987 86.7939C96.0167 86.8022 96.036 86.8105 96.0539 86.8188C96.2556 86.16 96.4102 85.6255 96.4834 85.3328C96.7652 84.2058 96.906 83.1493 97.5399 83.0085C98.1738 82.8676 99.0301 83.6244 99.7234 84.3467C101.415 86.1075 102.401 85.5441 102.683 85.2623C102.965 84.9806 103.176 83.9241 102.613 83.1493C102.049 82.3746 101.345 81.1068 101.274 79.4868C101.204 77.8668 103.106 75.682 105.924 74.9777C108.743 74.2734 111.138 75.8229 112.265 78.1486C113.392 80.4742 112.546 83.6438 110.504 86.2498C109.867 87.0618 108.957 87.8877 107.932 88.6735C108.51 88.6887 109.102 88.697 109.708 88.697C111 88.697 112.168 88.6777 113.231 88.6418C113.979 87.8601 114.596 87.0646 115.013 86.2733C117.408 81.7407 117.055 76.1764 113.323 73.123L113.321 73.1216Z"
            fill="#0C3BE9"
          />
        </g>
      </svg>
    </Box>
  )
}

export default NoResultsImage
