import { getDateWithoutTimeStamp } from 'utils'

const generateXAxis = (loginPeriod: number): string[] => {
  const xAxisDates = Array.from(
    /* current day inclusive, so add 1 */
    { length: loginPeriod + 1 },
    (_, i) => {
      const date = new Date()
      date.setDate(date.getDate() - i)
      return getDateWithoutTimeStamp(date)
    }
  ).reverse()

  return xAxisDates
}

export default generateXAxis
