import React, { useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Company } from 'types'
import { Close } from '@mui/icons-material'
import { formatOrgNr } from 'utils/formatOrgNr'

import GraphView from './GraphView'

import { IconButtonWrapper, UserActivityTableRow } from './'

interface ForetagsInloggningListItemProps {
  company: Company
  loginPeriod: number
}

const ForetagsInloggningListItem = ({
  company,
  loginPeriod,
}: ForetagsInloggningListItemProps) => {
  const [contentIsExpanded, setContentIsExpanded] = useState(false)
  const [displayAllUserLogins, setDisplayAllUserLogins] = useState(false)
  const { companyName, count, organizationNumber, userLogins, ansokningar } =
    company

  const shouldDisplayShowAllButton =
    userLogins.length > 5 && !displayAllUserLogins

  const sortedUserLogins = [...userLogins].sort((a, b) =>
    a.loginDates.length > b.loginDates.length ? -1 : 1
  )

  const loginDates = [...userLogins.map((user) => user.loginDates).flat()]

  const userAnsokningarCount = (userId: string) => {
    return ansokningar?.filter((ansokning) => ansokning.userId === userId)
      .length
  }

  const CardAction = () => {
    if (contentIsExpanded) {
      return (
        <IconButtonWrapper>
          <Close />
        </IconButtonWrapper>
      )
    }

    if (ansokningar?.length >= 1) {
      return (
        <Box marginTop={2} paddingRight={1}>
          <Typography variant="body2">
            {ansokningar.length}{' '}
            {ansokningar.length < 2 ? 'ansökan' : 'ansökningar'}
          </Typography>
        </Box>
      )
    }

    return null
  }

  const CardAvatar = () => {
    return (
      <Avatar
        sx={(theme) => ({
          backgroundColor: theme.palette.surface?.purple,
          color: theme.palette.primary.dark,
        })}
      >
        <Typography variant="subtitle1">{count}</Typography>
      </Avatar>
    )
  }

  // If there is only one login, the graph view is redundant so we won't show it
  const shouldShowGraphView = loginDates.length >= 2

  return (
    <Card
      sx={(theme) =>
        contentIsExpanded
          ? { border: `1px solid ${theme.palette.primary.main}` }
          : null
      }
    >
      <CardActionArea
        onClick={() => setContentIsExpanded(!contentIsExpanded)}
        data-testid="inloggnings-list-item-expand"
      >
        <CardHeader
          sx={{
            padding: '24px 16px',
          }}
          avatar={<CardAvatar />}
          title={
            <Typography variant="body1" fontWeight="bold">
              {companyName}
            </Typography>
          }
          subheader={formatOrgNr(organizationNumber)}
          action={<CardAction />}
        />
      </CardActionArea>
      <Collapse in={contentIsExpanded} unmountOnExit>
        {shouldShowGraphView && (
          <GraphView loginDates={loginDates} loginPeriod={loginPeriod} />
        )}
        <CardContent>
          <Typography variant="body1" fontWeight="bold">
            Aktivitet de senaste {loginPeriod} dagarna
          </Typography>
          <TableContainer component={Paper} elevation={0}>
            <Table data-testid="user-login-list" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Namn</TableCell>
                  <TableCell align="right">Ansökningar</TableCell>
                  <TableCell align="right">Inloggningar</TableCell>
                  <TableCell align="right">{null}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedUserLogins.map((user, index) => {
                  if (index > 4 && !displayAllUserLogins) return
                  return (
                    <UserActivityTableRow
                      name={user.name}
                      loginCount={user.loginDates.length}
                      emailAddress={user.emailAddress}
                      ansokningarCount={userAnsokningarCount(user.id)}
                      key={user.id}
                    />
                  )
                })}
              </TableBody>
            </Table>
            {shouldDisplayShowAllButton && (
              <Box mt={1}>
                <Button
                  data-testid="show-all-button"
                  variant="text"
                  size="small"
                  onClick={() => setDisplayAllUserLogins(true)}
                >
                  Visa alla ({sortedUserLogins.length})
                </Button>
              </Box>
            )}
          </TableContainer>
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default ForetagsInloggningListItem
