import React, { useEffect, useState } from 'react'
import { Company, Content, SalaryCategory } from 'types'
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { useGetCompanyLoginsQuery } from 'services/companyLogins'

import { ForetagsInloggningListItem } from './components'
import NoResultsPage from './components/NoResultsPage'

const ForetagsInloggningar = () => {
  const [loginPeriodInDays, setLoginPeriodInDays] = useState(7)
  const [companies, setCompanies] = useState<Company[] | undefined>([])
  const [currentPage, setCurrentPage] = useState(1)
  const { data, refetch, isLoading, isError, isSuccess } =
    useGetCompanyLoginsQuery(loginPeriodInDays)
  const [maxItemsPerPage, setMaxItemsPerPage] = useState(5)
  const [pageCount, setPageCount] = useState<number>(0)
  const [companySizeFilter, setCompanySizeFilter] = useState<
    SalaryCategory | 'all'
  >('all')

  useEffect(() => {
    const getCurrentCompanies = (): Company[] | undefined => {
      const companies = data?.aggregates
      if (companySizeFilter === 'all') return companies

      return companies?.filter(
        (company) => company.salary.category === companySizeFilter
      )
    }

    const isEmptyCompaniesPage = currentPage < 1

    const from = isEmptyCompaniesPage ? 0 : (currentPage - 1) * maxItemsPerPage
    const to = from + maxItemsPerPage

    const filteredCompanies = getCurrentCompanies()

    const newPageCount = Math.ceil(
      (filteredCompanies?.length as number) / maxItemsPerPage
    )
    setPageCount(newPageCount)

    setCompanies(filteredCompanies?.slice(from, to))
    if (isEmptyCompaniesPage) setCurrentPage(1)
    if (currentPage > pageCount) setCurrentPage(pageCount)
  }, [
    data?.aggregates,
    currentPage,
    maxItemsPerPage,
    pageCount,
    companySizeFilter,
  ])

  useEffect(() => {
    void refetch()
      .then(() => setCurrentPage(1))
      .catch(console.error)
  }, [loginPeriodInDays, refetch])

  const {
    foretagsInloggningar: {
      companyLoginListHeading,
      companyLoginListSubheading,
    },
  } = useEpiContent<Content>()

  const handleLoginPeriodChange = (e: SelectChangeEvent<number>) => {
    setLoginPeriodInDays(e.target.value as number)
  }

  const handleCompaniesPerPageChange = (e: SelectChangeEvent<number>) => {
    setMaxItemsPerPage(e.target.value as number)
  }

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page)
  }

  if (isLoading) return <CircularProgress data-testid="loading-spinner" />

  if (isError)
    return (
      <NoResultsPage
        title="Något gick fel vid hämtning av information"
        subtitle="Var vänlig försök igen. Kontakta KC om problemet kvarstår."
      />
    )

  if (isSuccess && data.aggregates.length < 1) return <NoResultsPage />

  return (
    <Box data-testid="foretags-inloggningar-main" component="main">
      <Box>
        <Typography variant="h6">{companyLoginListHeading}</Typography>
        <Typography
          variant="subtitle2"
          color={(theme) => theme.palette.text.secondary}
          mb={3}
        >
          {`${companyLoginListSubheading} ${loginPeriodInDays} dagarna`}
        </Typography>
      </Box>
      <Box display="flex" mb={3} component="section">
        <Box flex={6} display="flex" gap={1}>
          <Chip
            label="Alla"
            variant={companySizeFilter === 'all' ? 'filled' : 'outlined'}
            onClick={() => setCompanySizeFilter('all')}
          />
          <Chip
            label="Små"
            variant={companySizeFilter === 'small' ? 'filled' : 'outlined'}
            onClick={() => setCompanySizeFilter('small')}
          />
          <Chip
            label="Medel"
            variant={companySizeFilter === 'medium' ? 'filled' : 'outlined'}
            onClick={() => setCompanySizeFilter('medium')}
          />
          <Chip
            label="Stora"
            variant={companySizeFilter === 'large' ? 'filled' : 'outlined'}
            onClick={() => setCompanySizeFilter('large')}
          />
          <Chip
            label="Gigantiska"
            variant={companySizeFilter === 'extraLarge' ? 'filled' : 'outlined'}
            onClick={() => setCompanySizeFilter('extraLarge')}
          />
        </Box>
        <FormControl
          sx={{
            flex: 1,
            width: { xs: '50%', sm: '100%' },
            marginBottom: { xs: 3, sm: 0 },
          }}
          size="small"
        >
          <InputLabel id="inloggnings-period">Period</InputLabel>
          <Select
            value={loginPeriodInDays}
            onChange={handleLoginPeriodChange}
            fullWidth
            label="Period"
            labelId="inloggnings-period"
          >
            <MenuItem value={7}>7 dagar</MenuItem>
            <MenuItem value={10}>10 dagar</MenuItem>
            <MenuItem value={14}>14 dagar</MenuItem>
            <MenuItem value={20}>20 dagar</MenuItem>
            <MenuItem value={30}>30 dagar</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Stack
        gap={1}
        component="section"
        data-testid="foretagsinloggningar-list"
      >
        {companies && companies.length < 1 ? (
          <NoResultsPage />
        ) : (
          companies?.map((item: Company) => {
            return (
              <ForetagsInloggningListItem
                key={item.companyId}
                company={item}
                loginPeriod={loginPeriodInDays}
              />
            )
          })
        )}
        <Box sx={{ display: 'flex', mt: 4 }}>
          <Box flexGrow={2} />
          {pageCount > 1 && (
            <Pagination
              data-testid="pagination"
              count={pageCount}
              page={currentPage}
              onChange={handlePageChange}
            />
          )}
          <Box flexGrow={1} />
          <FormControl
            sx={{
              width: { xs: '25%' },
              marginBottom: { xs: 3, sm: 0 },
            }}
            size="small"
          >
            <InputLabel id="foretag-per-sida">Företag per sida</InputLabel>
            <Select
              value={maxItemsPerPage}
              onChange={handleCompaniesPerPageChange}
              fullWidth
              label="Företag per sida"
              labelId="foretag-per-sida"
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Stack>
    </Box>
  )
}

export default ForetagsInloggningar
