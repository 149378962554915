import { date as trrDateFormatter } from '@trr/frontend-datetime'
import { LoginFrequencyMap } from 'types'

// Remove timestamp from dates as we want to compare and order by year-month-day, not the time of day
const getDateWithoutTimeStamp = (date: string | Date): string => {
  if (typeof date === 'string') {
    return new Date(date).toISOString().split('T')[0]
  }

  return date.toISOString().split('T')[0]
}

export const isTodaysDate = (date: Date) => {
  const today = new Date().getDate()

  return date.getDate() === today
}

export const formatDate = (date: Date): string => {
  return isTodaysDate(date)
    ? 'Idag'
    : trrDateFormatter.format(date, { weekday: true })
}

export const getLoginFrequency = (loginDates: string[]): LoginFrequencyMap => {
  const dateSet = new Map<string, number>()

  loginDates
    .sort((a, b) => Date.parse(a) - Date.parse(b))
    .forEach((isoDate) => {
      const date = getDateWithoutTimeStamp(isoDate)
      if (dateSet.has(date)) {
        dateSet.set(date, (dateSet.get(date) as number) + 1)
      } else {
        dateSet.set(date, 1)
      }
    })

  return dateSet
}

export const getLoginCountPerDay = (
  xAxisDates: string[],
  loginsMap: LoginFrequencyMap
): number[] => {
  const result: number[] = []

  xAxisDates.forEach((date) => {
    if (!loginsMap.has(date)) {
      result.push(0)
    } else {
      const value = loginsMap.get(date) as number

      result.push(value)
    }
  })

  return result
}

export default getDateWithoutTimeStamp
