import { CompanyLoginsResult } from 'types'

import api from './api'

const companyLoginsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanyLogins: build.query<CompanyLoginsResult, number>({
      query: (numberOfDays: number) =>
        `/user-reporting/companyLogins?numberOfDays=${numberOfDays}&includeUserLogins=true&includeAnsokningar=true`,
      transformResponse: (response: CompanyLoginsResult) => {
        // Sort companies by number of logins
        return {
          ...response,
          aggregates: response.aggregates.sort((a, b) =>
            a.count < b.count ? 1 : -1
          ),
        }
      },
    }),
  }),
})

export const { useGetCompanyLoginsQuery } = companyLoginsApi
