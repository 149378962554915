import { Box, Container, Typography } from '@mui/material'
import ForetagsInloggningar from 'features/ForetagsInloggningar'
import { useUser, useEpiContent } from '@trr/app-shell-data'
import React from 'react'
import { Content } from 'types'

const App = () => {
  const user = useUser()
  const {
    foretagsInloggningar: { primarySubheading },
  } = useEpiContent<Content>()

  return (
    <Container maxWidth={'md'}>
      <Box mb={5}>
        <Typography variant="h2" mb={1}>
          Hej {user?.preferredFirstName}
        </Typography>
        <Typography
          variant="body1"
          color={(theme) => theme.palette.text.secondary}
        >
          {primarySubheading}
        </Typography>
      </Box>
      <ForetagsInloggningar />
    </Container>
  )
}

export default App
